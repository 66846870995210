<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form3"
          form="form3"
          id="form3"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Apellido y nombre -->
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="naAfiliado"
                label="Apellido/s y nombre/s"
                filled
                disabled
                readonly
                class="theme--light.v-input input"
                outlined
                dense
                type="text"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de documento -->
            <v-col cols="6" class="py-0">
               <v-autocomplete
                v-model="tipoDocSelected"
                :items="tiposDoc"
                label="Tipo de documento"
                item-text="value"
                item-value="id"
                disabled
                filled
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>

            <!-- Número de documento -->
            <v-col cols="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                disabled
                filled
                readonly
                class="theme--light.v-input input"
                label="N° de documento"
                v-model="documentoAfiliado"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="accionSelected"
                :items="itemsAccion"
                label="Operación"
                item-text="value"
                item-value="id"
                outlined
                clearable
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                ref="periodoD"
                v-model="periodo"
                label="Período"
                outlined
                dense
                clearable
                autocomplete="not"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="rules.required.concat(rules.periodoYyyyMm)"
                hint="Formato AAAAMM"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form3"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditForzarMovPadronSss",
  directives: { mask },
  props: {
    editarForzarMovPadronSss: {
      type: Object
    },
    fechaConsultaAfi: {
      type: String,
      required: false
    },
    benId: {
      type: Number
    }
  },
  data() {
    return {
      isFormValid: false,
      formEditTitle: enums.titles.EDITAR_MOV_PADRON_SSS,
      newTitle: enums.titles.NUEVO_MOV_PADRON_SSS,
      rules: rules,
      naAfiliado: null,
      tipoDocSelected: null,
      tiposDoc: [],
      documentoAfiliado: null,
      accionSelected: null,
      periodo: null,
      itemsAccion: [
        { id: "A", value: "Alta" },
        { id: "B", value: "Baja" },
        { id: "M", value: "Modificación" }
      ]
    };
  },
  created() {
    this.setTipoDocSelect();
    this.setDatosAfiliados();
    if (this.editarForzarMovPadronSss) {
      this.setForzarMovPadron(this.editarForzarMovPadronSss);
    } else {
      this.newForzarMovPadron();
    }
  },
  methods: {
    ...mapActions({
      saveForzarMovimientoSSS: "configAfiliaciones/saveForzarMovimientoSSS",
      getTiposDoc: "afiliaciones/getTiposDoc",
      getAfiliadoById: "afiliaciones/getAfiliadoById",
      getTiposDoc: "afiliaciones/getTiposDoc",
      setAlert: "user/setAlert"
    }),
    async setTipoDocSelect() {
      const response = await this.getTiposDoc();
      this.tiposDoc = response;
    },
    async setDatosAfiliados() {
      const response = await this.getAfiliadoById({
        benId: this.benId,
        fechaConsulta: this.fechaConsultaAfi
      });
       const tiposDoc = await this.getTiposDoc();
      this.tiposDoc = tiposDoc;
      this.tipoDocSelected = response.tipoDoc;
      this.naAfiliado = `${response.apellido} ${response.nombre}`;
      this.documentoAfiliado = response.dni;
    },
    async setForzarMovPadron(obj) {
      this.accionSelected = obj.accion;
      this.periodo = obj.periodo;
    },
    async newForzarMovPadron() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      const data = {
        benIncluirId: this.editarForzarMovPadronSss ? this.editarForzarMovPadronSss.benIncluirId : null, // <---
        benId: this.benId,
        accion: this.accionSelected,
        periodo: this.periodo
      };
      const res = await this.saveForzarMovimientoSSS(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
