<template>
  <v-card>
    <v-container>
      <v-card-text>
        <span class="h5 primary--text">{{ title }}</span>
      </v-card-text>
      <v-card-text>
        <v-card>
          <v-container>
            <v-card-text>
              <span class="h6 pl-1 primary--text">{{
                titleDatosUnificacion
              }}</span>
            </v-card-text>
            <!-- Tiene unificaciones -->
            <v-card-text v-if="unificaciones.length > 0">
              <v-data-table
                :items="unificaciones"
                :headers="headers"
                :items-per-page="5"
              >
              </v-data-table
            ></v-card-text>
          </v-container>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
        <div class="mx-2">
          <v-btn color="primary" @click="showDeleteModal = true">
            Confirmar
          </v-btn>
        </div>
        <DeleteDialog
          :titleProp="titleDelete"
          :textDelete="textDelete"
          :maxWidth="'40%'"
          :openDelete.sync="showDeleteModal"
          :isLoading="loadingDeleteBtn"
          :confirmButtonText="confirmDeleteBtnText"
          @onDeleteItem="confirmAction()"
        />
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";

export default {
  name: "RevertirUnificacion",
  components: {
    DeleteDialog
  },
  props: {
    unificaciones: {
      type: Array,
      required: true,
      default: []
    },
    benefOrigenData: {
      type: Object,
      required: true,
      default: {}
    },
    afiliadoData: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      title: `¿Desea revertir la última unificación del afiliado: ${this.afiliadoData}?`,
      titleDatosUnificacion: `Se reestablecerán los datos del afiliado: ${this.benefOrigenData.benDataAnterior}`, // Se reestableceran los datos de: Agrerar tipo y nro de doc
      headers: [
        {
          text: "Dato a recuperar",
          align: "start",
          sortable: false,
          value: "tablaDescripcion"
        },
        {
          text: "Cantidad de registros",
          align: "center",
          sortable: false,
          value: "cantidadRegistros"
        }
      ],
      titleDelete: "Revertir unificación",
      textDelete: `Al confirmar la reversión de la unificación, el afiliado ${this.benefOrigenData.benDataAnterior} volverá a crearse en el sistema y recuperará los datos asignados anteriormente al afiliado ${this.afiliadoData}`,
      showDeleteModal: false,
      loadingDeleteBtn: false,
      confirmDeleteBtnText: "Confirmar",
      reversionSuccess: false
    };
  },
  methods: {
    ...mapActions({
      revertirUnificacion: "afiliaciones/revertirUnificacion",
      setAlert: "user/setAlert"
    }),
    closeModal(response) {
      this.$emit("toggleModalRevertirUnificaciones", response);
    },
    async confirmAction() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.revertirUnificacion({
          benefOrigenId: this.benefOrigenData.benIdAnterior,
          unificaciones: this.unificaciones
        });
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Unificación revertida con éxito."
          });
          this.reversionSuccess = response.data.data;
          this.closeModal(this.reversionSuccess);
        }
      } catch (error) {}
      this.loadingDeleteBtn = false;
    }
  }
};
</script>

<style></style>
