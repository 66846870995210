<template>
  <v-card>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="itemsMovimientoPadronSss"
        dense
        :loading="loading"
        class="elevation-1"
        :search="search"
        sort-by="periodo"
        :sort-desc="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="9">
                <v-card-title>
                  <span class="pl-1 primary--text">{{ title }}</span>
                </v-card-title>
              </v-col>
              <v-col cols="3" class="pt-6">
                <v-btn color="primary" @click="openModal()" class="to-right">
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar movimiento en Padrón SSS</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteMovPadronSss(item.benIncluirId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar movimiento en Padrón SSS</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="40%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditForzarMovPadronSss
          :benId="benId"
          :fechaConsultaAfi="fechaConsultaAfi"
          :editarForzarMovPadronSss="editarForzarMovPadronSss"
          @closeAndReload="closeAndReload"
        ></EditForzarMovPadronSss>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'40%'"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditForzarMovPadronSss from "@/components/modules/afiliaciones/afiliados/EditForzarMovPadronSss.vue";

export default {
  components: { PageHeader, DeleteDialog, EditForzarMovPadronSss },
  name: "ForzarMovEnPadronSss",
  props: {
    benId: {
      type: Number
    },
    fechaConsultaAfi: {
      type: String,
      required: false
    }
  },
  data: () => ({
    title: "Forzar movimiento en padrón SSS",
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    loading: false,
    search: "",
    showDeleteModal: false,
    idToDelete: null,
    titleDelete: "¿Eliminar movimiento en padrón SSS?",
    itemsMovimientoPadronSss: [],
    editarForzarMovPadronSss: {},
    headers: [
      {
        text: "Período",
        value: "periodo",
        sortable: true
      },
      {
        text: "Operación",
        value: "accion"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadMovimientoPadronSss();
  },

  methods: {
    ...mapActions({
      getForzarMovimientoSss: "configAfiliaciones/getForzarMovimientoSss",
      deleteForzarMovimientoSSS: "configAfiliaciones/deleteForzarMovimientoSSS",
      setAlert: "user/setAlert"
    }),

    closeModal() {
      this.$emit("closeForzar");
    },
    async loadMovimientoPadronSss() {
      this.loading = true;
      const response = await this.getForzarMovimientoSss(this.benId);
      this.itemsMovimientoPadronSss = response;
      this.loading = false;
    },
    deleteMovPadronSss(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteForzarMovimientoSSS({
        benIncluirId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadMovimientoPadronSss();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editarForzarMovPadronSss = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.editarForzarMovPadronSss = {};
      this.loadMovimientoPadronSss();
    }
  }
};
</script>

<style lang="scss" scoped></style>
