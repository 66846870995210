<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ title }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-row>
          <!-- Token -->
          <v-col cols="2" sm="6" md="3" class="py-0" v-if="tokenGenerado">
            <v-text-field
              type="text"
              outlined
              dense
              label="Token"
              disabled
              v-model="token"
            ></v-text-field>
          </v-col>
          <!-- Vigencia -->
          <v-col cols="3" sm="6" md="3" class="py-0" v-if="tokenGenerado">
            <v-text-field
              type="text"
              outlined
              dense
              label="Vigencia"
              disabled
              v-model="vigencia"
            ></v-text-field>
          </v-col>
          <!-- Mensaje -->
          <v-col cols="12" sm="12" md="12" class="py-0" v-if="tokenGenerado">
            <v-text-field
              type="text"
              outlined
              dense
              label="Mensaje"
              disabled
              v-model="mensaje"
            ></v-text-field>
          </v-col>
          <!-- Observaciones -->
          <v-col cols="12" md="12" sm="12" class="py-0">
            <v-textarea
              v-model="observaciones"
              auto-grow
              :rules="
                observaciones !== null
                  ? [rules.maxLength(observaciones, 200)]
                  : []
              "
              :disabled="tokenGenerado"
              dense
              outlined
              label="Observaciones"
              rows="1"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-0 pr-4">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal">
          Cerrar
        </v-btn>
        <v-btn
          color="primary"
          :loading="loadingBtn"
          @click="generateToken()"
          v-if="!tokenGenerado"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "GenerarTokenAfiliado",
  props: {
    benId: {
      type: Number,
      required: true
    },
    benNom: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: `¿Desea generar un token para el afiliado '${this.benNom.trim()}'?`,
      rules: rules,
      loadingBtn: false,
      tokenGenerado: false,
      observaciones: null,
      token: null,
      mensaje: null,
      vigencia: null
    };
  },
  methods: {
    ...mapActions({
      tokenApp: "appBenef/tokenApp",
      setAlert: "user/setAlert"
    }),
    async generateToken() {
      this.loadingBtn = true;
      const data = {
        generadorBenId: this.benId,
        integranteBenId: this.benId,
        observaciones: this.observaciones
      };
      const response = await this.tokenApp(data);
      if (response.status === 200) {
        this.tokenGenerado = true;
        this.loadingBtn = false;
        this.token = response.data.data.token;
        this.mensaje = response.data.data.mensaje;
        this.vigencia = response.data.data.fechaVigencia;
        this.title = `Token generado: ${this.token}`
        this.setAlert({
          type: "success",
          message: "Token generado con éxito."
        });
      } else {
        this.loadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeModalGenerarToken");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
