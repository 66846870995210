<template>
  <v-card>
    <v-container>
      <div v-if="benefCambioHasItems">
        <v-card-title>
          <span class="pl-1 primary--text">
            Movimientos relacionados a eliminar
          </span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="tablasBenefCambio"
            :headers="headersTablasBenefCambio"
            class="elevation-1"
            items-per-page="5"
          >
          </v-data-table>
        </v-card-text>
      </div>
      <div v-if="benefHasItems">
        <v-card-title>
          <span class="pl-1 primary--text">
            Registros del afiliado a borrar que pasarán al afiliado destino
          </span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="tablasBenef"
            :headers="headersTablasBenef"
            class="elevation-1"
            :items-per-page="5"
          >
          </v-data-table>
        </v-card-text>
      </div>
      <div v-if="!benefCambioHasItems && !benefHasItems">
        <v-card-title>
          <span class="pl-1 primary--text">
            No existen registros a borrar que pasarán al afiliado destino.
          </span>
        </v-card-title>
      </div>
      <v-card-actions>
        <v-subheader v-if="benefCambioHasItems" style="color: red;"
          >Es necesario eliminar los movimientos para unificar el
          afiliado.</v-subheader
        >
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()">
          Cerrar
        </v-btn>
        <div class="mx-2">
          <v-btn
            color="primary"
            @click="openDeleteModal()"
            :disabled="benefCambioHasItems"
          >
            Unificar afiliado
          </v-btn>
        </div>
        <DeleteDialog
          :titleProp="titleDelete"
          :textDelete="textDelete"
          :maxWidth="'40%'"
          :openDelete.sync="showDeleteModal"
          :isLoading="loadingDeleteBtn"
          :confirmButtonText="confirmDeleteBtnText"
          @onDeleteItem="confirmAction()"
        />
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";

export default {
  name: "TablasRelacionadasAfiliado",
  components: {
    DeleteDialog
  },
  props: {
    tablasBenefCambio: {
      type: Array,
      required: false,
      default: []
    },
    tablasBenef: {
      type: Array,
      required: false,
      default: []
    },
    oldBenId: {
      type: Number,
      required: false
    },
    newBenId: {
      type: Number,
      required: false
    }
  },
  data: vm => ({
    showDeleteModal: false,
    loadingDeleteBtn: false,
    headersTablasBenefCambio: [
      {
        text: "Movimiento a eliminar",
        align: "start",
        sortable: false,
        value: "childTableNameDescription"
      },
      {
        text: "Cantidad de registros",
        align: "center",
        sortable: false,
        value: "cantidad"
      }
    ],
    headersTablasBenef: [
      {
        text: "Dato a unificar",
        align: "start",
        sortable: false,
        value: "childTableNameDescription"
      },
      {
        text: "Cantidad de registros",
        align: "center",
        sortable: false,
        value: "cantidad"
      }
    ],
    titleDelete: "¿Desea unificar el afiliado?",
    textDelete:
      "Al confirmar la unificación se eliminará definitivamente del sistema el afiliado a eliminar seleccionado y los registros correspondientes pasarán a formar parte del afiliado destino seleccionado.",
    confirmDeleteBtnText: "Confirmar"
  }),
  computed: {
    benefCambioHasItems() {
      if (this.tablasBenefCambio.length > 0) return true;
      else return false;
    },
    benefHasItems() {
      if (this.tablasBenef.length > 0) return true;
      else return false;
    }
  },
  methods: {
    ...mapActions({
      unificarAfiliados: "afiliaciones/unificarAfiliados",
      setAlert: "user/setAlert"
    }),
    openDeleteModal() {
      if (this.tablasBenef.length === 0 && this.tablasBenefCambio.length === 0)
        this.textDelete = "";
      this.showDeleteModal = true;
    },
    async confirmAction() {
      this.loadingDeleteBtn = true;
      try {
        const data = {
          tablasToEdit: this.tablasBenef,
          newBenId: this.newBenId,
          oldBenId: this.oldBenId
        };
        const response = await this.unificarAfiliados(data);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Afiliado unificado con éxito."
          });
          this.closeModal(response.data.data);
        }
      } catch (error) {}
      this.loadingDeleteBtn = false;
    },
    closeModal(response) {
      this.$emit("toggleModalTablasRelacionadas", response);
    }
  }
};
</script>

<style></style>
