<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="confirmBaja()"
        >
          <v-row>
            <!-- Número afiliado -->
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                filled
                readonly
                label="Número de afiliado"
                v-model="numeroAfi"
                autocomplete="off"
                disabled
              ></v-text-field>
            </v-col>
            <!-- Documento -->
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                filled
                readonly
                label="Documento"
                v-model="documento"
                autocomplete="off"
                disabled
              ></v-text-field>
            </v-col>
            <!-- Apellido -->
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                filled
                readonly
                label="Apellido/s"
                v-model="apellido"
                autocomplete="off"
                disabled
              ></v-text-field>
            </v-col>
            <!-- Nombre -->
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                filled
                readonly
                label="Nombre/s"
                v-model="nombre"
                autocomplete="off"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-menu
                ref="menu"
                v-model="menuFecha"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaBajaTextField"
                    label="Fecha baja"
                    :append-icon="calendarIcon"
                    hint="formato DD/MM/AA"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="fechaBaja = parseDateToIso(fechaBajaTextField)"
                    clearable
                    dense
                    outlined
                    autocomplete="off"
                    :rules="rules.required.concat(rules.validDate)"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaBaja"
                  no-title
                  scrollable
                  @change="
                    fechaBajaTextField = formatDate(fechaBaja);
                    loadDatosAfiliado();
                  "
                  @input="menuFecha = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-model="estadosSelected"
                :items="estadosCombo"
                item-text="value"
                item-value="id"
                label="Nuevo estado"
                outlined
                clearable
                dense
                @change="updateMotivosBaja"
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-model="motivoBajaSelected"
                :items="motivoBajaCombo"
                item-text="value"
                item-value="id"
                label="Motivo"
                outlined
                clearable
                dense
                no-data-text="Seleccione un nuevo estado"
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <!-- Observaciones -->
            <v-col cols="12" sm="6" md="12" class="py-0">
              <v-textarea
                v-model="observaciones"
                label="Observaciones"
                dense
                outlined
                auto-grow
                rows="1"
                :rules="
                  observaciones !== null
                    ? [rules.maxLength(observaciones, 254)]
                    : []
                "
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-data-table
                v-model="integrantesSelected"
                :headers="headers"
                :items="integrantes"
                item-key="dni"
                class="elevation-1"
                show-select
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col align-self="center">
                        <span class="primary--text">{{ titleTable }}</span>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.fechaUltMov`]="{ item }">
                  <span>{{ functionFormatDate(item.fechaUltMov) }}</span>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" sm="6" md="5" class="py-0">
              <v-select
                v-model="motivoBajaIntegrantesSelected"
                :items="motivoBajaCombo"
                item-text="value"
                item-value="id"
                label="Motivo para integrantes del grupo"
                outlined
                clearable
                dense
                no-data-text="Seleccione un nuevo estado"
                :disabled="isDisabled"
                :rules="this.isDisabled === false ? rules.required : []"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
    <section class="d-flex justify-end">
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @onConfirm="saveBaja()"
        @update:openConfirm="cancelar"
      />
    </section>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import moment from "moment";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
  name: "BajaAfiliados",
  props: {
    benId: {
      type: Number,
      required: false
    },
    fechaConsultaAfi: {
      type: String,
      required: false
    }
  },
  components: {
    ConfirmDialog
  },
  data: vm => ({
    title: "Baja de afiliados",
    numeroAfi: null,
    isFormValid: false,
    rules: rules,
    documento: null,
    apellido: null,
    nombre: null,
    calendarIcon: enums.icons.CALENDAR,
    menuFecha: false,
    fechaBajaTextField: null,
    fechaBaja: new Date().toISOString().substr(0, 10),
    estadosSelected: null,
    estadosCombo: [],
    motivoBajaSelected: null,
    motivoBajaCombo: [],
    motivoBajaIntegrantesSelected: null,
    integrantes: [],
    integrantesSelected: [],
    afiliadosSeleccionados: [],
    afiliadosTotales: [],
    afiliado: {},
    isDisabled: true,
    textConfirmDialog: "",
    observaciones: null,
    openConfirmDialog: false,
    confirmaFecha: false,
    titleTable: "Registrar baja a los integrantes del grupo familiar",
    headers: [
      {
        text: "Documento",
        align: "start",
        sortable: false,
        value: "dni"
      },
      { text: "Apellido/s y nombre/s", value: "apellidoYNombre" },
      { text: "Parentesco", value: "parentesco" },
      { text: "Fecha último movimiento", value: "fechaUltMov" },
      { text: "Estado", value: "estadoNombre" }
    ]
  }),
  created() {
    this.fechaBajaTextField = this.formatDate(this.fechaConsultaAfi);
    this.fechaBaja = this.parseDateToIso(this.fechaBajaTextField);
    this.loadDatosAfiliado();
    this.loadIntegrantesFamiliar();
    this.setEstadosBenefSelect();
  },
  watch: {
    integrantesSelected() {
      if (this.integrantesSelected.length !== 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
        this.motivoBajaIntegrantesSelected = null;
      }
    }
  },
  methods: {
    ...mapActions({
      getIntegrantesGrupoFamiliar: "afiliaciones/fetchIntegrantesConCobertura",
      getEstadosBenef: "afiliaciones/getEstadosBenef",
      getMotivosBaja: "afiliaciones/getMotivosBaja",
      getAfiliado: "afiliaciones/getAfiliadoSimpleById",
      postBajaAfiliado: "afiliaciones/postBajaAfiliado",
      setAlert: "user/setAlert"
    }),
    async setEstadosBenefSelect() {
      const response = await this.getEstadosBenef();
      this.estadosCombo = response;
    },
    async updateMotivosBaja(id) {
      if (id == undefined && id == null) {
        this.motivoBajaCombo = [];
      } else {
        const response = await this.getMotivosBaja(id);
        this.motivoBajaCombo = response;
      }
    },
    async loadDatosAfiliado() {
      const response = await this.getAfiliado(this.benId);
      this.nombre = response.nombre;
      this.apellido = response.apellido;
      this.numeroAfi = response.nroAfiliado;
      this.documento = response.dni;
    },
    async loadIntegrantesFamiliar() {
      const response = await this.getIntegrantesGrupoFamiliar({
        benId: this.benId,
        fechaConsulta: this.fechaConsultaAfi
      });
      this.integrantes = response;
      this.afiliado = { benId: this.benId };
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    cancelar() {
      this.afiliadosSeleccionados = [];
      this.afiliadosTotales = [];
      this.confirmaFecha = false;
    },
    confirmDate(mensaje) {
      this.textConfirmDialog = mensaje;
      this.openConfirmDialog = true;
      this.confirmaFecha = true;
      this.integrantesSelected.forEach(integrante => {
        this.afiliadosSeleccionados.push(integrante);
      });
      this.afiliadosSeleccionados.push(this.afiliado);
      let data = {};
      this.afiliadosSeleccionados.forEach(afiliado => {
        if (afiliado.benId == this.benId) {
          data = {
            benId: this.benId,
            fechaBaja: this.parseDateToIso(this.fechaBajaTextField),
            observaciones: this.observaciones,
            estadoBajaId: this.estadosSelected,
            tipoMovMotivoId: this.motivoBajaSelected,
            confirmaFecha: this.confirmaFecha
          };
        } else {
          data = {
            benId: afiliado.benId,
            fechaBaja: this.parseDateToIso(this.fechaBajaTextField),
            observaciones: this.observaciones,
            estadoBajaId: this.estadosSelected,
            tipoMovMotivoId: this.motivoBajaIntegrantesSelected,
            confirmaFecha: this.confirmaFecha
          };
        }
        this.afiliadosTotales.push(data);
      });
    },
    confirmBaja() {
      this.openConfirmDialog = true;
      this.integrantesSelected.forEach(integrante => {
        this.afiliadosSeleccionados.push(integrante);
      });
      this.afiliadosSeleccionados.push(this.afiliado);
      let data = {};
      this.afiliadosSeleccionados.forEach(afiliado => {
        data = {
          benId: afiliado.benId == this.benId ? this.benId : afiliado.benId,
          fechaBaja: this.parseDateToIso(this.fechaBajaTextField),
          observaciones: this.observaciones,
          estadoBajaId: this.estadosSelected,
          tipoMovMotivoId:
            afiliado.benId == this.benId
              ? this.motivoBajaSelected
              : this.motivoBajaIntegrantesSelected,
          confirmaFecha: this.confirmaFecha
        };
        this.afiliadosTotales.push(data);
      });
      if (this.afiliadosTotales.length == 1) {
        this.textConfirmDialog = `¿Confirma la baja del afiliado ${this.apellido} ${this.nombre}?`;
      } else {
        this.textConfirmDialog = `¿Confirma la baja del afiliado ${this.apellido} ${this.nombre} y los integrantes seleccionados del grupo familiar?`;
      }
    },
    async saveBaja() {
      if (this.afiliadosTotales.length == 1) {
        const res = await this.postBajaAfiliado(this.afiliadosTotales);
        if (res.status === 200 && res.data.data === true) {
          this.openConfirmDialog = false;
          this.setAlert({
            type: "success",
            message: `El afiliado ${this.apellido} ${this.nombre} ha sido dado de baja exitosamente.`
          });
          this.closeModal();
        } else if (res.data.data.errorFecha) {
          this.confirmDate(res.data.data.mensaje);
        } else {
          this.setAlert({
            type: "warning",
            message: res.data.data.mensaje
          });
        }
      } else {
        const res = await this.postBajaAfiliado(this.afiliadosTotales);
        if (res.status === 200 && res.data.data === true) {
          this.openConfirmDialog = false;
          this.setAlert({
            type: "success",
            message: `El afiliado ${this.apellido} ${this.nombre} y los integrantes seleccionados del grupo familiar han sido dados de baja exitosamente.`
          });
          this.closeModal();
        } else if (res.data.data.errorFecha) {
          this.confirmDate(res.data.data.mensaje);
        } else {
          this.setAlert({
            type: "warning",
            message: res.data.data.mensaje
          });
        }
      }
    }
  }
};
</script>
<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
