<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="toggleModalRevertirUnificaciones()"
          :disabled="!benefHasUnificaciones"
          >Revertir</v-btn
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="pl-2" v-on="on" v-bind="attrs" color="primary">
              {{ infoIcon }}</v-icon
            >
          </template>
          <span v-if="benefHasUnificaciones"
            >Revertir la última unificación del afiliado seleccionado</span
          >
          <span v-else
            >No existen unificaciones a revertir para el afiliado
            seleccionado</span
          >
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-card>
          <v-container>
            <v-card-title>
              <span class="pl-1 primary--text">{{ titleAfiOrigen }}</span>
            </v-card-title>
            <v-row>
              <!-- Número afiliado -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  filled
                  readonly
                  label="Número de afiliado"
                  v-model="numeroAfi"
                  autocomplete="off"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- Documento -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  filled
                  readonly
                  label="Documento"
                  v-model="documento"
                  autocomplete="off"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- Apellido -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  filled
                  readonly
                  label="Apellido/s"
                  v-model="apellido"
                  autocomplete="off"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- Nombre -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  filled
                  readonly
                  label="Nombre/s"
                  v-model="nombre"
                  autocomplete="off"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card class="mt-2">
          <v-container>
            <v-card-title>
              <span class="pl-1 primary--text">{{ titleAfiDestino }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    disabled
                    label="Afiliado destino"
                    v-model.trim="afiliadoDestinoSelectedNom"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Boton buscar por nombre o dni -->
                <v-col cols="1" class="py-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        absolute
                        fab
                        small
                        color="primary"
                        @click="modalBusquedaAfiliado = true"
                      >
                        <v-icon>{{ searchIcon }}</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Buscar afiliado por nombre, apellido, DNI o número</span
                    >
                  </v-tooltip>
                </v-col>
                <!-- Número afiliado destino -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    disabled
                    label="Número de afiliado destino"
                    v-model="numeroAfiliadoDestino"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
        <div class="mx-2">
          <v-btn
            color="primary"
            @click="validarUnificacion()"
            :loading="isUnificacionValidating"
            :disabled="afiliadoDestinoId == null"
          >
            Siguiente
          </v-btn>
        </div>
      </v-card-actions>
      <v-dialog
        v-model="modalBusquedaAfiliado"
        v-if="modalBusquedaAfiliado"
        @keydown.esc="toggleModalBusquedaAfi"
        max-width="40%"
        persistent
      >
        <BusquedaAfiliado
          @toggleModalBusquedaAfi="toggleModalBusquedaAfi"
          @findAfiliado="findAfiliado"
        ></BusquedaAfiliado>
      </v-dialog>
      <v-dialog
        v-if="modalTablasRelacionadas"
        v-model="modalTablasRelacionadas"
        @keydown.esc="toggleModalTablasRelacionadas"
        max-width="50%"
        persistent
      >
        <TablasRelacionadas
          :tablasBenefCambio="tablasRelacionadasBenefCambio"
          :tablasBenef="tablasRelacionadasBenef"
          :oldBenId="benId"
          :newBenId="afiliadoDestinoId"
          @toggleModalTablasRelacionadas="toggleModalTablasRelacionadas($event)"
        />
      </v-dialog>
      <v-dialog
        v-if="modalRevertirUnificaciones"
        v-model="modalRevertirUnificaciones"
        @keydown.esc="toggleModalRevertirUnificaciones"
        max-width="60%"
        persistent
      >
        <RevertirUnificacion
          :unificaciones="unificacionesBenef"
          :benefOrigenData="benefOrigenData"
          :afiliadoData="`${apellido} ${nombre} - ${tipoDoc} - ${documento}`"
          @toggleModalRevertirUnificaciones="
            toggleModalRevertirUnificaciones($event)
          "
        />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import BusquedaAfiliado from "@/components/modules/afiliaciones/afiliados/BusquedaAfiliado.vue";
import TablasRelacionadas from "@/components/modules/afiliaciones/afiliados/TablasRelacionadasAfiliado.vue";
import RevertirUnificacion from "@/components/modules/afiliaciones/afiliados/RevertirUnificacion.vue";

export default {
  name: "UnificacionesAfiliado",
  components: {
    BusquedaAfiliado,
    TablasRelacionadas,
    RevertirUnificacion
  },
  props: {
    benId: {
      type: Number,
      required: false
    }
  },
  data: vm => ({
    title: enums.titles.UNIFICAR_AFILIADO,
    infoIcon: enums.icons.SNB_INFO,
    titleAfiOrigen: "Afiliado a eliminar",
    titleAfiDestino: "Afiliado destino",
    numeroAfi: null,
    rules: rules,
    documento: null,
    apellido: null,
    nombre: null,
    tipoDoc: null,
    searchIcon: enums.icons.SEARCH,
    afiliadoDestinoSelectedNom: null,
    afiliadoDestinoId: null,
    numeroAfiliadoDestino: null,
    modalBusquedaAfiliado: false,
    isUnificacionValidating: false,
    modalTablasRelacionadas: false,
    benefCambioHasItems: false,
    tablasRelacionadasBenefCambio: [],
    tablasRelacionadasBenef: [],
    unificacionesBenef: [],
    benefHasUnificaciones: false,
    benefOrigenData: null,
    modalRevertirUnificaciones: false
  }),
  created() {
    this.loadDatosAfiliadoOrigen();
  },
  methods: {
    ...mapActions({
      getAfiliado: "afiliaciones/getAfiliadoSimpleById",
      validarUnificacionAfiliados: "afiliaciones/validarUnificacionAfiliados",
      afiliadoHasUnificaciones: "afiliaciones/afiliadoHasUnificaciones",
      setAlert: "user/setAlert"
    }),
    async loadDatosAfiliadoOrigen() {
      const afiliado = await this.getAfiliado(this.benId);
      this.nombre = afiliado.nombre;
      this.apellido = afiliado.apellido;
      this.numeroAfi = afiliado.nroAfiliado;
      this.documento = afiliado.dni;
      this.tipoDoc = afiliado.tipoDocNom;
      const unificacionesData = await this.afiliadoHasUnificaciones(this.benId);
      this.unificacionesBenef = unificacionesData.logUnificaciones;
      this.benefHasUnificaciones = unificacionesData.hasUnificaciones;
      this.benefOrigenData = unificacionesData.benefOrigenData;
    },
    async validarUnificacion() {
      this.isUnificacionValidating = true;
      try {
        if (this.benId === this.afiliadoDestinoId) {
          this.setAlert({
            type: "warning",
            message:
              "No es posible continuar con la unificación ya que el afiliado a eliminar y el afiliado coinciden."
          });
          this.isUnificacionValidating = false;
          return;
        }
        const validate = await this.validarUnificacionAfiliados(this.benId);
        if (validate.status === 200) {
          this.tablasRelacionadasBenefCambio =
            validate.data.data.tablasRelacionadasBenefCambio;
          this.tablasRelacionadasBenef =
            validate.data.data.tablasRelacionadasBenef;
          this.toggleModalTablasRelacionadas();
        }
      } catch (error) {
        console.error(error);
      }
      this.isUnificacionValidating = false;
    },
    closeModal(response) {
      this.$emit("toggleModalUnificarAfiliado", response);
    },
    toggleModalBusquedaAfi() {
      this.modalBusquedaAfiliado = !this.modalBusquedaAfiliado;
    },
    toggleModalTablasRelacionadas(response) {
      this.modalTablasRelacionadas = !this.modalTablasRelacionadas;
      if (response != null) this.closeModal(response); // Si devuelve algo el response es que se unificó el afiliado, por lo tanto emito un evento para cargar los filtros de la pantalla principal
    },
    toggleModalRevertirUnificaciones(response) {
      this.modalRevertirUnificaciones = !this.modalRevertirUnificaciones;
      if (response) this.loadDatosAfiliadoOrigen();
    },
    findAfiliado(afiliadoSelected) {
      this.afiliadoDestinoSelectedNom = afiliadoSelected.value;
      this.afiliadoDestinoId = afiliadoSelected.id;
      this.numeroAfiliadoDestino = afiliadoSelected.numero;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
